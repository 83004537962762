<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "OpenFormTypesContentsIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedItemType: null,
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("openFormTypes", {
      contents: state => state.openformtypes
    })
  },

  methods: {
    initContent() {
      return {
        oft_name: "",
        oft_description: "",
        nlp_engine: "dialogflow",
        project_id: "",
        region_id: "",
        dialogflow_es_environment: "",
        test_query_text: "",
        test_language_code: "",
        is_system: false,
        ac_id: "",
        speech_complete_timeout: 1000,
        speech_incomplete_timeout: 20000,
        no_input_timeout: 5000,
        speech_start_timeout: 50,
        inter_result_timeout: 0,
        barge_in_sensitivity: 0.5,
        auto_punctuation: false,
        profanity_filter: false,
        single_utterance: true,
        lex_bot_name: "",
        lex_bot_alias: "",
        lex_role_arn: "",
        lex_region: "",
        lex_profile_name: "",
        oft_outputs: [],
        is_archived: 0,
        test_query_parameters: "",
        instance_id: "",
        assistant_id: "",
        service_api_key: "",
        api_version: "default",
        agent_name: "",
        dialogflow_cx_use_end_interaction: false
      };
    }
  },
  mounted() {
    this.contentType = "OpenFormType";
    this.primaryKey = "open_form_type_id";
  }
};
</script>

<style lang="scss" scoped></style>
